<template>
  <section id="money-details">
    <b-container fluid class="px-0 h-100">
      <b-row no-gutters class="align-items-center h-100">
        <b-col md="6">
          <section class="img-wrap ratio-100">
            <div class="img-content">
              <b-img :src="require('@/assets/imgs/money.png')" fluid></b-img>
            </div>
          </section>
        </b-col>
        <b-col md="6">
          <article class="text-center">
            <h2 class="my-5 font-bold">{{ $t("title.money") }}</h2>
            <read-more
              more-str=""
              less-str=""
              :text="moneyFlow"
              class="description"
              :max-chars="210"
            ></read-more>
            <!-- <p class="description mx-auto mb-5" v-html="moneyFlow"></p> -->
            <router-link to="/money-details" class="router-link mt-5"
              ><span>{{ $t("button.more_info") }}</span></router-link
            >
          </article>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  name: "moneyFlow",
  props: {
    moneyFlow: {
      type: String,
      default: ""
    }
  }
};
</script>

<style lang="scss" scoped></style>
